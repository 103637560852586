// vue
import { ref } from 'vue'

// nuxt
import { useNuxtApp } from '#app'

// pinia
import { defineStore } from 'pinia'

// types
import type { TimeZone } from '@revolutionprep/types'
import type { NitroFetchOptions } from 'nitropack'

export const useTimeZoneStore = defineStore('time-zones', () => {
  // fetch repositories
  const { $timeZones } = useNuxtApp()

  // state
  const timeZones = ref<TimeZone[]>([])

  // actions
  async function index (config?: NitroFetchOptions<string>) {
    const _timeZones =
      await $timeZones.index<TimeZone[]>(config)
    timeZones.value = _timeZones
    return timeZones
  }

  return {
    index,
    timeZones
  }
})
